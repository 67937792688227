<script setup lang="ts">
  import { useMpScreen } from '@borg/ui';

  const { isMobile } = useMpScreen();
  const projectConfig = useProjectConfig();

  const { data: jobGroups } = await exclusiveJobsRepository.getMany();
  const noOfExclusiveJobGroups = projectConfig.noOfExclusiveJobGroups;

  const groupsToDisplay = computed(() =>
    jobGroups.value ? jobGroups.value.slice(0, noOfExclusiveJobGroups) : [],
  );
</script>

<template>
  <HomepageJobsSection
    v-if="groupsToDisplay.length"
    :title="$t('INDEX_PAGE.EXCLUSIVE_JOBS_SECTION_TITLE')"
  >
    <div
      v-if="!isMobile"
      class="exclusive-container"
    >
      <div
        v-for="jobGroup in groupsToDisplay"
        :key="jobGroup.id"
        class="exclusive-container__item"
      >
        <JobCardExclusive :job-group="jobGroup" />
      </div>
    </div>
    <JobCardFeatured
      v-for="item in groupsToDisplay"
      v-else
      :key="item.id"
      :job-group="item"
      source="homepage_exclusive"
      class="featured-column"
    />
  </HomepageJobsSection>
</template>

<style scoped lang="scss">
  .exclusive-container {
    $self: &;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    $gap: var(--mp-space-50);
    gap: $gap;

    @include screen-xl {
      flex-direction: row;
    }

    #{$self}__item {
      width: 100%;

      @include screen-xl {
        width: calc(calc(100% / 3) - $gap);
      }
    }

    &__item {
      box-sizing: border-box;
      flex: 1 1 calc(calc(100% / 3) - $gap);
    }
  }

  .featured-column {
    margin-bottom: var(--mp-space-50);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
</style>
